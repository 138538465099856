<template>
  <div class="text-center card">
    <div>
      <h1>{{ $t("login.notVerified.title") }}</h1>

      <v-img
        :src="require('@/assets/images/auth/clock-circle.png')"
        max-width="114"
        class="mx-auto my-10"
        alt="Image"
      />
    </div>

    <v-spacer />
    <div>
      <p>
        {{ $t("login.notVerified.text1") }}
      </p>

      <v-divider class="my-5" />

      <p>
        {{ $t("login.notVerified.text2") }}
      </p>

      <div class="text-center" v-if="showButton">
        <v-btn
          color="primary"
          @click="sendVerificationLink"
          :loading="$loading('auth/sendVerificationLink')"
        >
          {{ $t("login.notVerified.button") }}
        </v-btn>
      </div>

      <small class="d-block text-center mt-3">
        <a @click="$emit('back')">
          {{ $t("login.returnToLogin") }}
        </a>
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: String,
  },

  data: () => ({
    showButton: true,
  }),

  methods: {
    sendVerificationLink() {
      this.$store
        .dispatch("auth/sendVerificationLink", this.email)
        .then((data) => {
          this.$toast.success(data.message);
          this.showButton = false;
        })
        .catch(() => {
          this.$toast.error(this.$t("common.error"));
        });
    },
  },
};
</script>

<style></style>
