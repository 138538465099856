<template>
  <div class="card">
    <div class="text-center">
      <h1>{{ $t("login.title") }}</h1>
      <div class="subtitle-1">{{ $t("login.subtitle") }}</div>
    </div>

    <v-alert
      v-if="$route.query['registration-success']"
      class="mt-4"
      type="success"
      text
    >
      {{ $t("register.completedRegistrationAlert") }}
    </v-alert>

    <v-alert v-if="$route.query['notApproved']" class="mt-4" type="error">
      Your account is not approved.
    </v-alert>

    <v-form
      v-model="formValid"
      @submit.prevent="submit"
      class="mt-10 text-right"
    >
      <div>
        <!-- Email input -->
        <v-text-field
          v-model="formData.email"
          :label="$t('common.email')"
          type="email"
          prepend-inner-icon="mdi-email"
          filled
          :rules="[$rules.required, $rules.email]"
        />

        <!-- Password input -->
        <password-input
          v-model="formData.password"
          :label="$t('login.password')"
          type="password"
          prepend-inner-icon="mdi-key"
          filled
          :rules="[$rules.required]"
        />

        <!-- Forgot password -->
        <small>
          <router-link
            :to="{ name: 'auth.forgot-password' }"
            class="d-block mb-5"
          >
            {{ $t("login.forgotPassword") }}
          </router-link>
        </small>

        <!-- OR divider -->
        <!-- <v-row justify="center" align="center" class="my-5">
        <v-col><v-divider /></v-col>
        <v-col cols="auto" class="text-h6">
          {{ $t("common.or").toUpperCase() }}
        </v-col>
        <v-col><v-divider /></v-col>
      </v-row> -->

        <!-- Google login -->
        <!-- <v-btn color="primary" block :href="getSocialLink('google')">
        <v-icon left>mdi-google</v-icon>
        {{ $t("login.loginGoogle") }}
      </v-btn> -->

        <!-- Facebook login -->
        <!-- <v-btn
        color="primary"
        block
        :href="getSocialLink('facebook')"
        class="mt-4 mb-10"
      >
        <v-icon left>mdi-facebook</v-icon>
        {{ $t("login.loginFacebook") }}
      </v-btn> -->
      </div>
      <v-spacer />
      <div>
        <!-- ApiResponse -->
        <api-response :response="formResponse" class="mt-5" />

        <!-- Login button -->
        <v-btn
          color="primary"
          type="submit"
          :disabled="!formValid"
          :loading="$loading('auth/login')"
          block
        >
          {{ $t("login.login") }}
        </v-btn>

        <!-- Register link -->
        <small class="d-block text-center mt-3">
          {{ $t("login.noAccount") }}
          <b>
            <router-link :to="{ name: 'auth.register' }">
              {{ $t("login.signUp") }}
            </router-link>
          </b>
        </small>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    formData: Object,
  },

  data: () => ({
    formValid: false,
    formResponse: null,
  }),

  methods: {
    submit() {
      this.formResponse = null;
      this.$store
        .dispatch("auth/login", this.formData)
        .then((data) => {
          if (data.message === "2fa_code_sent") {
            this.$emit("two-factor");
          } else {
            this.$emit("success");
          }
        })
        .catch((err) => {
          switch (err.error) {
            case "invalid_credentials":
              this.formResponse = {
                message: this.$t("login.loginFailed"),
              };
              break;

            case "not_verified":
              this.$emit("not-verified");
              break;

            case "not_onboarded":
              this.$router.push({
                name: "auth.register.onboard",
                params: {
                  token: err.registration_token,
                },
              });
              break;

            case "user_inactive":
              this.formResponse = {
                message: "Sorry, your account is not approved.",
              };
              break;

            default:
              this.formResponse = {
                message: this.$t("common.error"),
              };
              break;
          }
        });
    },

    getSocialLink(provider) {
      return this.$utils.env("API_URL") + `auth/${provider}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-divider {
  border-color: white !important;
  opacity: 0.6;
}
</style>
