<template>
  <div class="page">
    <LoginForm
      v-if="view === 'login'"
      :formData="formData"
      @two-factor="view = 'two-factor'"
      @not-verified="view = 'not-verified'"
      @success="onLoginSuccess"
    />

    <TwoFactorForm
      v-if="view === 'two-factor'"
      :formData="formData"
      @success="onLoginSuccess"
      @back="view = 'login'"
    />

    <NotVerified
      v-if="view === 'not-verified'"
      :email="formData.email"
      @back="view = 'login'"
    />
  </div>
</template>

<script>
import LoginForm from "@/components/auth/login/LoginForm.vue";
import TwoFactorForm from "@/components/auth/login/TwoFactorForm.vue";
import NotVerified from "@/components/auth/login/NotVerified.vue";

export default {
  metaInfo: {
    title: "Login",
  },

  components: { LoginForm, TwoFactorForm, NotVerified },

  data: () => ({
    formData: {},
    view: "login",
  }),

  methods: {
    onLoginSuccess() {
      const redirect = this.$route.query.redirect || { name: "app" };
      this.$router.replace(redirect).then(() => {
        this.$toast.info(this.$t("login.welcome"));
      });
    },
  },
};
</script>
