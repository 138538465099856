<template>
  <div class="card">
    <div
      class="text-center d-flex flex-column justify-space-between"
      style="height: 40%"
    >
      <h1>{{ $t("login.twoFA.title") }}</h1>
      <div class="subtitle-1">{{ $t("login.twoFA.subtitle") }}</div>
    </div>

    <v-form @submit.prevent="submit" class="mt-5 text-right">
      <!-- Code -->
      <v-otp-input
        v-model="formData.code"
        :loading="$loading('auth/loginVerify')"
        type="number"
        class="my-5 flex-grow-0 code__input"
        @finish="(otp) => (formData.code = otp)"
        :rules="[$rules.required]"
      />

      <v-spacer />

      <!-- ApiResponse -->
      <api-response :response="formResponse" class="mt-5" />

      <!-- Verify button -->
      <v-btn
        color="primary"
        type="submit"
        :loading="$loading('auth/loginVerify')"
        block
        class="flex-grow-0"
      >
        {{ $t("common.verify") }}
      </v-btn>

      <!-- Back to login -->
      <small class="d-block text-center mt-3">
        <a href="#" @click="$emit('back')">
          {{ $t("login.returnToLogin") }}
        </a>
      </small>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    formData: Object,
  },

  data: () => ({
    formResponse: null,
  }),

  created() {
    this.formData.code = "";
  },

  methods: {
    submit() {
      this.formResponse = null;
      this.$store
        .dispatch("auth/loginVerify", this.formData)
        .then(() => {
          this.$emit("success");
        })
        .catch((data) => {
          this.formResponse = data;
        });
    },
  },
};
</script>

<style lang="scss">
.code__input {
  display: flex;
  align-self: center;

  .v-input__slot {
    width: 40px !important;
    height: 48px !important;
    border-radius: 4px !important;
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .v-input:nth-child(3) {
    padding-right: 10px !important;
  }

  .v-input:nth-child(4) {
    padding-left: 10px !important;
  }

  .v-text-field__slot {
    font-weight: 600 !important;
  }
}
</style>
